import '../../App.css'
import { useState } from 'react'

function LihNews(props) {

  return (
    <section id="lih-news" className="m-t-5 m-b-5">
      <div className="news--wrapper ">

        <div className="news--text news--container">
          <img src="/images/headline_the_crucial.png" alt="LIH News From Likes to Loyalty Embracing social media’s evolution in defining your strategy" className="w-75" />
          <a href="https://www.news.lih.com/" className="news--btn">
            <span>Read more</span>
          </a>
        </div>

        <div className="news--cover news--container">
          <img src="/images/ilustration_the_crucial.png" alt='LIH News From Likes to Loyalty Embracing social media’s evolution in defining your strategy' className="w-100 desktop" />
          <img src="/images/ilustration_the_crucial_mob.png" alt='LIH News From Likes to Loyalty Embracing social media’s evolution in defining your strategy' className="w-100 mobile" />

        </div>

      </div>
    </section>
  );
}

export default LihNews;


