import '../../App.css';
import { useState } from 'react';

function Header(props) {

  return (
    <div id="headerTag">
        <header id="header">
<div className="container">
    <div className="logo loaded">
        <a href="/" className="editContent">
            <img src="images/logo-lih-o-s.png" className="light" alt="logo"
                />
            <img src="images/logo-lih-o.png" className="dark" alt="logo"
                />
        </a>
    </div>
    <a href="/" className="menu-button-open loaded mobile-only me-2">
    <span className="line1"></span>
    <span className="line2"></span>
    <span className="line3"></span>
   </a>
    <div className="d-flex justify-content-center">
        <nav className="main-nav mobile-hide">
            <a className="p-4" href="/">home</a>
            <a className="p-4 btn-work" href="#portfolio">work</a>
            <a className="p-4 btn-services" href="">services</a>
            <a className="p-4 btn-news" href="">news</a>
            <a className="p-4 btn-contact" href="">contact</a>
        </nav>
    </div>

    <div className="menu-lightbox">
            <header id="lightbox-header">
                <div className="container">
                    <div className="span12">
                        <div className="logo"><a href="/" className="editContent"
                                style={{outline: "none", cursor: "inherit"}}><img src="images/logo-lih-o.png" alt="logo"
                                    style={{outline: "none", cursor: "inherit"}}/></a>
                        </div>
                        <a href="#" className="menu-button-close"><span className="line1"></span><span className="line2"></span></a>
                    </div>
                </div>
            </header>
            <div className="dtable">
                <div className="dtcell">
                    
                    <ul id="menu" className="menu">
                        <li><a href="/" style={{outline: "none", cursor: "inherit"}}><span>home</span></a></li>
                        <li><a className="btn-work" href="javascript:void(0)" style={{outline: "none", cursor: "inherit"}}><span>work</span></a></li>
                        <li><a className="btn-services" href="javascript:void(0)" style={{outline: "none", cursor: "inherit"}}><span>services</span></a></li>
                        <li><a className="btn-news" href="javascript:void(0)" style={{outline: "none", cursor: "inherit"}}><span>news</span></a></li>
                        <li><a className="btn-contact" href="javascript:void(0)" style={{outline: "none", cursor: "inherit"}}><span>contact</span></a></li>
                    </ul>
                   
                </div>
            </div>
     </div>
</div>
</header>


</div>
  );
}

export default Header;


