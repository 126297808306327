import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';


import Timeline from "./components/Timeline";
import Timelineimage from "./components/containers/Timelineimage";
import VideoSection from "./components/containers/VideoSection";
import Header from "./components/containers/Header";
import About from "./components/containers/About";
import Work from "./components/containers/Work";
import Awards from "./components/containers/Awards";
import Clients from "./components/containers/Clients";
import Partners from "./components/containers/Partners";
import IcomBanner from "./components/containers/IcomBanner";
import Services from "./components/containers/Services";
import LihNews from "./components/containers/LihNews";
import Contact from "./components/containers/Contact";
import Footer from "./components/containers/Footer";


function App() {

  // const [state, setState] = useState(0);

  // setTimeout(function() {
  //   setState(state = true);
    
  // },5000);
 

  return (
    <div id="page" className="App page lih-theme">
      

      <Header />
      <VideoSection />
      <Timelineimage />
      <Timeline className="">  
      </Timeline>
     <About />
     <Work />
     <Awards />
     <Clients />
     {/* <Partners /> */}
     <IcomBanner />
     <Services />
     <LihNews />
     <Contact />
     <Footer />
    </div>
  );
}

export default App;
